import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby"

import ReactMarkdown from 'react-markdown';
import tocbot from 'tocbot';

import Image from './image';
import Heading from './heading';

const Guide = () => {
  
  useEffect(() => {
    tocbot.init({
      orderedList: false,
      headingSelector: 'h1, h2, h3',
    });
  });

  const data = useStaticQuery(graphql`
    query GuideMdQuery {
      file(name: {eq: "guide"}) {
        internal {
          content
        }
      }
    }  
  `)
  
  const content = data.file.internal.content;

  const renderers = {
    image: Image,
    heading: Heading
  }

  return (
    <div className="guide">
      <div className="sidenav">
        <div className="js-toc"></div>
      </div>
      <div className="main">
        <div className="js-toc-content">
          {/* <span dangerouslySetInnerHTML={{ __html: md.render(content) }} /> */}
          <ReactMarkdown 
            escapehtml={false}
            source={content} 
            renderers={renderers} 
          />
        </div>
        <div style={{height: "50px"}}></div>
      </div>
    </div>
  )
};

export default Guide;
