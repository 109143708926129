import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = ({src}) => {

  const imgSrc = src.split('=')[0];
  const size = src.split('=')[1] || 300;

  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  const img = data.allImageSharp.edges.find((element) => {
    // Match string after final slash
    return (element.node.fluid.src.split('/').pop() === imgSrc);
  }).node.fluid;

  var width1 = size; // max-width: 320px
  var width2 = size; // max-width: 640px
  var width3 = size;

  if (size === "800") {
    width1 = 300;
    width2 = 600;
  } else if (size === "600") {
    width1 = 300;
    width2 = 600;
  }

  img.sizes = "(max-width: 320px) " + width1 + "px, (max-width: 640px) " + width2 + "px, " + width3 + "px";
  return (<Img 
    fluid={img}
    style={{      
      margin: "auto",
      maxWidth: size + "px"
    }}
    imgStyle={{
      objectPosition: "center center",
      objectFit: 'contain' 
    }}  
  />);
}

export default Image
