import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Guide from "../components/guide";


const IndexPage = () => (
  <Layout>
    <SEO title="Guide" />
    <Guide />
  </Layout>
)

export default IndexPage
